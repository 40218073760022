module.exports = {
    head: {
        a: ' หน้าแรก',
        b: 'เกมเซ็นเตอร์',
        c: 'ส่งเสริม',
        d: 'ภาษา',
    },
    tedian: 'ลักษณะเด่นของแบรนด์: วิดีโอเกมโพไซดอน อิเล็กทรอนิกส์ มีรูปแบบการเล่นเกมที่หลากหลายและน่าสนใจ โดยมุ่งเน้นที่การพัฒนาและนวัตกรรมของเกม ทำให้ผู้เล่นได้ย้อนรำลึกถึงความคลาสสิก และในขณะเดียวกันก็เต็มไปด้วยความประหลาดใจ ความสดใหม่และประสบการณ์แบบภาพและเสียง เป็นแบรนด์ที่นักเล่มเกมเลือกแป็นความบันเทิงในระดับต้น ๆ ด้วยเทคโนโลยีล้ำสมัย บริษัทได้พัฒนาเกมมากมายพร้อมเนื้อหาที่หลากหลายเพื่อให้ผู้เล่นได้รับประสบการณ์การเล่นเกมที่ดีที่สุด',
    iconl: {
        a: 'ขายตรงอย่างเป็นทางการ',
        b: 'การรับรองระดับสากล',
        c: 'รับประกันความมั่งคงทางการเงิน',
        d: 'อัตราต่อรองสูงมาก',
    },
    down:{
        ios:'ดาวน์โหลด',
        an:'ดาวน์โหลดแอนดรอยด์',
    },
    privacy:'นโยบายความเป็นส่วนตัว'
}